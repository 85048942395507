import { ReportType } from "@src/modules/DeviceStatus/Devices/DeviceInfoView/DeviceInfoSidebar/DeviceInfo/hooks/useDownloadReports";

export const API_URL = String(process.env.REACT_APP_API_URL);
export const CMS_URL = String(process.env.REACT_APP_CMS_URL);

export const REFRESH_TOKEN_ENDPOINT = "accounts/refresh-token";
export const REVOKE_TOKEN_ENDPOINT = "accounts/revoke-token";
export const PROFILE_ENDPOINT = "accounts/profile";
export const EDIT_PROFILE_ENDPOINT = "accounts/profile";
export const EDIT_USER_SETTINGS_ENDPOINT = "accounts/settings";
export const ACTIVATE_SUBSCRIPTION_ENDPOINT = "accounts/subscription-code";
export const SALES_CONTACT_ENDPOINT = "accounts/sales-contact";
export const CONTACT_WITH_SALES_ENDPOINT = "accounts/contact-with-sales";
export const FINISH_SETUP_ENDPOINT = "accounts/finish-setup";
export const ACTIVATION_ENDPOINT = "accounts/activate";
export const REGISTER_ENDPOINT = "accounts/register";
export const VALIDATE_RESET_KEY_ENDPOINT = "accounts/validate-reset-key";
export const FORGOT_PASSWORD_ENDPOINT = "accounts/forgot-password";
export const RESET_PASSWORD_ENDPOINT = "accounts/reset-password";
export const TOKEN_ENDPOINT = "accounts/token";
export const ACCOUNT_ENDPOINT = "accounts";
export const LOCATIONS_ENDPOINT = "locations";
export const DEVICES_ENDPOINT = "devices";
export const DEVICES_TOOLBAR_ENDPOINT = `${DEVICES_ENDPOINT}/toolbar`;
export const DEVICES_STATUS_ENDPOINT = `${DEVICES_ENDPOINT}/status`;
export const MODALITIES_ENDPOINT = "modalities";
export const TEST_EQUIPMENTS_ENDPOINT = "test-equipments";
export const PHANTOMS_ENDPOINT = "phantoms";
export const STUDIES_ENDPOINT = "studies";
export const CUSTOM_STUDIES_ENDPOINT = "studies/custom";
export const STUDIES_DICOM_ENDPOINT = `${STUDIES_ENDPOINT}/dicom`;
export const NOTIFICATIONS_ENDPOINT = "notificationsHub";
export const CONNECTOR_ENDPOINT = "connector";
export const CONNECTOR_LOGS_ENDPOINT = "connector-logs";
export const SCHEDULER_ENDPOINT = "scheduler";
export const DISMISS_TASK_ENDPOINT = `${SCHEDULER_ENDPOINT}/dismiss`;
export const GROUPS_ENDPOINT = "groups";
export const ADMIN_ENDPOINT = "admin";
export const ADMIN_USERS_ENDPOINT = "admin/users";
export const ADMIN_LICENSE_CODES_ENDPOINT = "admin/redeem-codes";
export const ADMIN_PRICING_PLANS_ENDPOINT = "admin/pricing-plans";
export const ADMIN_MINED_STUDIES_ENDPOINT = "admin/mined-studies";
export const SELECT_PHANTOM_ENDPOINT = "studies/select-connector-results";
export const DICOM_ENDPOINT = "dicom";
export const STUDY_GROUP_ENDPOINT = "study-group";

export const KB_VIDEOS_ENDPOINT = "kbvideos";
export const KB_TUTORIALS_ENDPOINT = "knowledge-base-tutorials";
export const KB_TAGS_ENDPOINT = "tags";
export const KB_PHANTOMS_ENDPOINT = "phantoms";
export const UPDATES_ENDPOINT = "updates";

export const getDeviceInfoUrl = (deviceId: string) =>
  `${DEVICES_ENDPOINT}/${deviceId}/info`;

export const getDevicesGeneralInfoUrl = (deviceId: string) =>
  `${DEVICES_ENDPOINT}/${deviceId}/general`;

export const getDevicePhantomsUrl = (deviceId: string) =>
  `${DEVICES_ENDPOINT}/${deviceId}/phantoms`;

export const getConnectorSeriesPhantomsUrl = (connectorSeriesId: string) =>
  `${DEVICES_ENDPOINT}/connector-series-phantoms/${connectorSeriesId}`;

export const getDeviceDefaultTestsUrl = (
  deviceId: string,
  phantomCode: number | string,
) => `${DEVICES_ENDPOINT}/${deviceId}/default-tests/${phantomCode}`;

export const getReanalyzeSingleTestUrl = (studyId: string, testId: number) =>
  `${STUDIES_ENDPOINT}/${studyId}/${testId}/reanalyze`;

export const getDeviceTolerancesUrl = (deviceId: string, phantomCode: number) =>
  `${DEVICES_ENDPOINT}/${deviceId}/tolerances/${phantomCode}`;

export const getTolerancesTemplateUrl = (
  phantomCode: number,
  country: string,
) => `${DEVICES_ENDPOINT}/tolerances/${phantomCode}/${country}`;

export const getBaseStudyUrl = (deviceId: string, studyId: string) =>
  `${STUDIES_ENDPOINT}/${deviceId}/${studyId}`;

export const getSingleStudyDetailUrl = (studyId: string) =>
  `${STUDIES_ENDPOINT}/${studyId}/details`;

export const getReanalyzeStudyUrl = (deviceId: string, studyId: string) =>
  `${getBaseStudyUrl(deviceId, studyId)}/reanalyze`;

export const getStudyDicomsUrl = (deviceId: string, studyId: string) =>
  `${getBaseStudyUrl(deviceId, studyId)}/dicoms`;

export const getDownloadStudyReportsUrl = (
  deviceId: string,
  studyId: string,
  type: ReportType,
) => `${getBaseStudyUrl(deviceId, studyId)}/reports/${type}`;

export const getStudiesBaseUrl = (deviceId: string, date: string) =>
  `${STUDIES_ENDPOINT}/${deviceId}/${date}`;

export const getDeleteStudiesUrl = (deviceId: string, date: string) =>
  `${getStudiesBaseUrl(deviceId, date)}`;

export const getReanalyzeStudiesUrl = (deviceId: string, date: string) =>
  `${getStudiesBaseUrl(deviceId, date)}/reanalyze`;

export const getDownloadDicomsUrl = (deviceId: string, date: string) =>
  `${getStudiesBaseUrl(deviceId, date)}/dicoms`;

export const getDownloadReportsUrl = (
  deviceId: string,
  date: string,
  type: ReportType,
) => `${getStudiesBaseUrl(deviceId, date)}/reports/${type}`;

export const getResultsUrl = (deviceId: string, date: string) =>
  `${getStudiesBaseUrl(deviceId, date)}/results`;

export const getTestBaseUrl = (studyId: string, testId: string) =>
  `${STUDIES_ENDPOINT}/${studyId}/${testId}`;

export const getSingleTestResultsUrl = (studyId: string, testId: string) =>
  `${getTestBaseUrl(studyId, testId)}/test-results`;

export const getEditSingleTestUrl = (
  studyId: string,
  testId: string,
  clearSnapshot: boolean,
) =>
  `${getTestBaseUrl(studyId, testId)}/edit-test?clearSnapshot=${clearSnapshot}`;

export const getEditSingleCustomTestUrl = (studyId: string, testId: string) =>
  `${getTestBaseUrl(studyId, testId)}/edit-custom-test`;

export const getSetAsBenchmarkUrl = (studyId: string, testId: string) =>
  `${getTestBaseUrl(studyId, testId)}/set-as-benchmark`;

export const getDeleteTestUrl = (studyId: string, testId: string) =>
  `${getTestBaseUrl(studyId, testId)}/delete-test`;

export const getStudyImagesUrl = (studyId: string, testId: string) =>
  `${STUDIES_ENDPOINT}/${studyId}/${testId}/images`;

export const getStudyGroupUrl = (studyGroupId: string) =>
  `${STUDY_GROUP_ENDPOINT}/${studyGroupId}`;

export const getAssignStudyToGroupUrl = (studyId: string) =>
  `${STUDIES_ENDPOINT}/${studyId}/assign-to-group`;

export const getCustomStudyDicomEndpoint = (studyId: string) =>
  `${STUDIES_DICOM_ENDPOINT}/${studyId}`;
