import { DICOM_VIEWER } from "@src/common/styles/const";

const rootPaths = {
  HOME: "/",
  STORYBOOK: "/storybook",
  LOGIN: "/login",
  REGISTER: "/register",
  ACTIVATE: "/activate/:activationKey",
  RESET_PASSWORD: "/reset-password/:resetToken?",
  DEVICE_STATUS: "/device-status",
  KNOWLEDGE_BASE: "/knowledge-base",
  DICOM_VIEWER: `${DICOM_VIEWER}/:studyId?`,
  GROUPS: "/groups/:groupId?",
  ADMIN: "/admin",
} as const;

const deviceStatusSubRoutes = {
  LOCATIONS: rootPaths.DEVICE_STATUS,
  DEVICES: `${rootPaths.DEVICE_STATUS}/:locationId`,
  DEVICE: "/device",
  TEST_EQUIPMENT: `${rootPaths.DEVICE_STATUS}/test-equipment`,
} as const;

const devicesSubRoutes = {
  MAIN: deviceStatusSubRoutes.DEVICES,
  NEW: `${deviceStatusSubRoutes.DEVICES}/new-device`,
  EDIT: `${deviceStatusSubRoutes.DEVICE}/:deviceId/edit`,
  EDIT_GENERAL: `${deviceStatusSubRoutes.DEVICE}/:deviceId/edit/general`,
  EDIT_DEFAULT_TESTS: `${deviceStatusSubRoutes.DEVICE}/:deviceId/edit/tests/:phantomCode`,
  EDIT_TOLERANCES: `${deviceStatusSubRoutes.DEVICE}/:deviceId/edit/tolerances/:phantomCode`,
  STUDY_GROUPS: `${deviceStatusSubRoutes.DEVICE}/:deviceId/groups`,
  RESULTS: `${deviceStatusSubRoutes.DEVICE}/:deviceId/results`,
} as const;

const testEquipmentSubRoutes = {
  MAIN: deviceStatusSubRoutes.TEST_EQUIPMENT,
  REGISTER: `${deviceStatusSubRoutes.TEST_EQUIPMENT}/register`,
  EDIT: `${deviceStatusSubRoutes.TEST_EQUIPMENT}/edit/:equipmentId`,
} as const;

const knowledgeBaseSubRoutes = {
  BASE: rootPaths.KNOWLEDGE_BASE,
  FMM: `${rootPaths.KNOWLEDGE_BASE}/fmm`,
} as const;

const adminSubRoutes = {
  MAIN: rootPaths.ADMIN,
  USERS: `${rootPaths.ADMIN}/users/:page?`,
  REDEEM_CODES: `${rootPaths.ADMIN}/redeem-codes/:page?`,
  STUDY_PANEL: `${rootPaths.ADMIN}/studies/:studyId`,
  DICOM_MINER: `${rootPaths.ADMIN}/miner`,
  DICOM_MINER_DETAILS: `${rootPaths.ADMIN}/miner/:phantomCode/:page?`,
};

const routesPaths = {
  ...rootPaths,
  DEVICE_STATUS: {
    ...deviceStatusSubRoutes,
    DEVICES: {
      ...devicesSubRoutes,
    },
    TEST_EQUIPMENT: {
      ...testEquipmentSubRoutes,
    },
  },
  KNOWLEDGE_BASE: {
    ...knowledgeBaseSubRoutes,
  },
  ADMIN: { ...adminSubRoutes },
} as const;

export default routesPaths;
