import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ProfileData } from "@src/modules/App/hooks/useProfile";
import { ReactComponent as Edit } from "@src/modules/App/Profile/ViewProfile/icons/edit.svg";
import {
  ProfileColumn,
  ProfileDetailsWrapper,
  UserEmail,
  UserName,
} from "@src/modules/App/Profile/ViewProfile/ProfileDetails/ProfileDetails.components";
import ProfileButton from "@src/modules/App/Topbar/ProfileButton";
import Button from "@src/modules/shared/components/Button";

interface Props extends Pick<ProfileData, "name" | "email"> {
  goToEdit: () => void;
}

const ProfileDetails: FunctionComponent<Props> = ({
  email,
  name,
  goToEdit,
}) => {
  const { t } = useTranslation();

  return (
    <ProfileDetailsWrapper>
      <ProfileButton size="medium" />
      <ProfileColumn>
        <UserName>{name}</UserName>
        <UserEmail>{email}</UserEmail>
        {/* <Button buttonType="secondary" size="small">
          <People />
          {t("My Group")}
        </Button> */}
      </ProfileColumn>
      <Button buttonType="tertiary" onClick={goToEdit}>
        <Edit />
        {t("Change")}
      </Button>
    </ProfileDetailsWrapper>
  );
};

export default ProfileDetails;
