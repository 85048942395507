import { RouteComponentProps } from "react-router";

import { flatNestedRoutes } from "@src/modules/Routing/helpers";
import routesPaths from "@src/modules/Routing/routesPaths";

export interface RouteConfig {
  path: string;
  title: string;
  type: RouteType;
  exact?: boolean;
  importComponent: () => Promise<{
    default:
      | React.ComponentType<RouteComponentProps<undefined>>
      | React.ComponentType<unknown>;
  }>;
}

type RouteType = "authed" | "unique" | "public";

export type NestedRouteConfig = RouteConfig & {
  children?: NestedRouteConfig[];
};

const uniqueRoutes: RouteConfig[] = [
  {
    path: routesPaths.STORYBOOK,
    title: "Storybook",
    type: "unique",
    exact: true,
    importComponent: () =>
      import("@src/modules/Storybook/" /* webpackChunkName: "Storybook" */),
  },
];

const publicRoutes: RouteConfig[] = [
  {
    path: routesPaths.LOGIN,
    title: "Login",
    type: "public",
    exact: true,
    importComponent: () =>
      import("@src/modules/Public/Login" /* webpackChunkName: "Login" */),
  },
  {
    path: routesPaths.REGISTER,
    title: "Register",
    type: "public",
    exact: true,
    importComponent: () =>
      import("@src/modules/Public/Register" /* webpackChunkName: "Register" */),
  },
  {
    path: routesPaths.ACTIVATE,
    title: "Activate",
    type: "public",
    exact: true,
    importComponent: () =>
      import("@src/modules/Public/Activate" /* webpackChunkName: "Activate" */),
  },
  {
    path: routesPaths.RESET_PASSWORD,
    title: "Reset Password",
    type: "public",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/Public/ResetPassword" /* webpackChunkName: "ResetPassword" */
      ),
  },
];

const authedRoutes: NestedRouteConfig[] = [
  {
    path: routesPaths.HOME,
    title: "Home",
    type: "authed",
    exact: true,
    importComponent: () =>
      import("@src/modules/Home/" /* webpackChunkName: "Home" */),
  },
  {
    path: routesPaths.DEVICE_STATUS.LOCATIONS,
    title: "Device Status",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus" /* webpackChunkName: "LocationDevices" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.DEVICES.MAIN,
    title: "Devices",
    type: "authed",
    exact: true,
    importComponent: () =>
      import("@src/modules/DeviceStatus" /* webpackChunkName: "Devices" */),
  },
  {
    path: routesPaths.DEVICE_STATUS.DEVICES.NEW,
    title: "Add device",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus/Devices/AddDevice" /* webpackChunkName: "AddDevice" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.DEVICES.STUDY_GROUPS,
    title: "Study groups",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus" /* webpackChunkName: "DeviceGroups" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.DEVICES.RESULTS,
    title: "Device info",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus/Devices/DeviceInfoView" /* webpackChunkName: "DeviceInfo" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.DEVICES.EDIT,
    title: "Edit device",
    type: "authed",
    exact: false,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus/Devices/EditDevice" /* webpackChunkName: "EditDevice" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.TEST_EQUIPMENT.MAIN,
    title: "Test equipment",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus" /* webpackChunkName: "TestEquipment" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.TEST_EQUIPMENT.REGISTER,
    title: "Add test equipment",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus/TestEquipment/AddTestEquipment" /* webpackChunkName: "AddTestEquipment" */
      ),
  },
  {
    path: routesPaths.DEVICE_STATUS.TEST_EQUIPMENT.EDIT,
    title: "Edit test equipment",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/DeviceStatus/TestEquipment/EditTestEquipment" /* webpackChunkName: "EditTestEquipment" */
      ),
  },
  {
    path: routesPaths.KNOWLEDGE_BASE.BASE,
    title: "Knowledge Base",
    type: "authed",
    exact: true,
    importComponent: () =>
      import(
        "@src/modules/KnowledgeBase" /* webpackChunkName: "KnowledgeBase" */
      ),
  },
  {
    path: routesPaths.KNOWLEDGE_BASE.FMM,
    title: "Frequently Made Mistakes",
    type: "authed",
    exact: true,
    importComponent: () => import("@src/modules/KnowledgeBase"),
  },
  {
    path: routesPaths.DICOM_VIEWER,
    title: "Dicom Viewer",
    type: "authed",
    exact: true,
    importComponent: () =>
      import("@src/modules/DicomViewer" /* webpackChunkName: "DicomViewer" */),
  },
  {
    path: routesPaths.GROUPS,
    title: "Groups",
    type: "authed",
    exact: true,
    importComponent: () =>
      import("@src/modules/Groups" /* webpackChunkName: "Groups" */),
  },
  {
    path: routesPaths.ADMIN.MAIN,
    title: "Admin",
    type: "authed",
    importComponent: () =>
      import("@src/modules/Admin" /* webpackChunkName: "Admin" */),
  },
];

export const nestedRoutes: NestedRouteConfig[] = [
  ...uniqueRoutes,
  ...publicRoutes,
  ...authedRoutes,
];

const routes: RouteConfig[] = flatNestedRoutes(nestedRoutes);

export default routes;
