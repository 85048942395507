import axios from "axios";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AXIOS_CMS_ERROR } from "@src/common/helpers/toastIds";
import { CMS_URL } from "@src/config/baseUrls";
import { getUserToken } from "@src/common/helpers/userToken";

export const useAxiosCMS = () => {
  const { t } = useTranslation();
  const userToken = getUserToken();

  return useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userToken?.token}`,
      },
      withCredentials: false,
      baseURL: CMS_URL,
    });

    axiosInstance.interceptors.response.use(undefined, (error) => {
      if (error)
        toast.error(t("Couldn't fetch data"), { toastId: AXIOS_CMS_ERROR });
    });

    return axiosInstance;
  }, [t, userToken]);
};
